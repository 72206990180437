import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import en from '@angular/common/locales/en';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
  provideZoneChangeDetection,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter, Router } from '@angular/router';
import { provideTransloco } from '@jsverse/transloco';
import { LoadingBarHttpClientModule, provideLoadingBarInterceptor } from '@ngx-loading-bar/http-client';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';
import { HotkeyModule } from 'angular2-hotkeys';
import { en_US, provideNzI18n } from 'ng-zorro-antd/i18n';
import { catchError, Observable, of } from 'rxjs';
import { environment } from '../environments/environment';
import { BASE_PATH as LOGIC_CORE_BASE_PATH, LogicCoreApiModule } from '../generated-code/logic-core-api';
import { BASE_PATH as ROLE_MANAGER_BASE_PATH, RoleManagerApiModule } from '../generated-code/role-manager-api';
import { routes } from './app.routes';
import { authInterceptor } from './core/interceptors/auth.interceptor';
import { httpErrorInterceptor } from './core/interceptors/http-error.interceptor';
import { AuthService } from './core/services/auth.service';
import { PortalsBridgeService } from './core/services/portal-bridge.service';
import { provideNzIcons } from './icons-provider';
import { AdminState } from './store/admin/admin.state';
import { ConfigurationState } from './store/configuration/configuration.state';
import { DoesState } from './store/does/does.state';
import { ExperimentsState } from './store/experiments/experiments.state';
import { NgxsHistoryModule } from './store/plugins/ngxs-history-plugin';
import { ProjectState } from './store/project/project.state';
import { VisualizationsState } from './store/visualizations/visualizations.state';
import { TranslocoHttpLoader } from './transloco-loader';

registerLocaleData(en);

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: ROLE_MANAGER_BASE_PATH, useValue: environment.roleManagerApiBaseUrl },
    { provide: LOGIC_CORE_BASE_PATH, useValue: environment.logicCoreApiBaseUrl },
    PortalsBridgeService,
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideNzIcons(),
    provideNzI18n(en_US),
    importProvidersFrom(FormsModule),
    provideAnimationsAsync(),
    /*importProvidersFrom(JwtModule.forRoot({})),
    provideHttpClient(withInterceptorsFromDi()),*/
    importProvidersFrom(RoleManagerApiModule),
    importProvidersFrom(LogicCoreApiModule),
    provideHttpClient(withInterceptors([httpErrorInterceptor, authInterceptor])),
    importProvidersFrom(
      NgxsModule.forRoot(
        [ConfigurationState, AdminState, ProjectState, ExperimentsState, DoesState, VisualizationsState],
        {
          developmentMode: false, //!environment.production,
        },
      ),
    ),
    importProvidersFrom(
      NgxsHistoryModule.forRoot({
        historyLength: 25,
      }),
    ),
    importProvidersFrom(HotkeyModule.forRoot({ cheatSheetCloseEsc: true })),
    importProvidersFrom(
      NgxsReduxDevtoolsPluginModule.forRoot({
        disabled: false,
      }),
    ),
    /*importProvidersFrom(
      NgxsLoggerPluginModule.forRoot({
        disabled: false,
      })
    ),*/
    importProvidersFrom(NgxsRouterPluginModule.forRoot()),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeUserData,
      multi: true,
      deps: [Router, AuthService],
    },
    provideHttpClient(),
    provideLoadingBarInterceptor(),
    importProvidersFrom(LoadingBarHttpClientModule),
    provideTransloco({
      config: {
        availableLangs: ['en', 'es'],
        defaultLang: 'en',
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
  ],
};

export function initializeUserData(router: Router, authService: AuthService): () => Observable<void> {
  return (): Observable<void> => {
    const valid = authService.isTokenValid();
    const refreshToken = localStorage.getItem('refreshToken') as string;
    if (valid) {
      const token = localStorage.getItem('accessToken') as string;
      return authService.initUser(token);
    } else if (refreshToken && authService.isRefreshTokenValid()) {
      return authService.makeRefresh(refreshToken).pipe(
        catchError((err) => {
          console.error(err);
          router.navigate(['/login']);
          return of();
        }),
      );
    } else {
      router.navigate(['/login']);
      return of();
    }
  };
}
