<div class="sidebar fxColumn h-100">
  <div class="sidebar-logo" [class.collapsed]="isCollapsed()">
    <a [routerLink]="['/projects', activeTenant()?.id]" [queryParams]="{ folder: null }">
      @if (!isCollapsed()) {
        <span class="logo">DXTER</span>
        <!--<img src="/assets/images/TNP_logo_WHITE.png" alt="logo" />-->
      } @else {
        <span class="logo">D<span style="position: relative; top: 5px; font-weight: 400">x</span></span>
        <!--<img src="/assets/images/TNP_anagrama_WHITE.png" alt="logo" />-->
      }
    </a>
  </div>
  <div class="flex-1">
    <ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed()">
      <!--<li
        nz-menu-item
        [routerLink]="['/projects', activeTenant()?.id]"
        [queryParams]="{ folder: null }"
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed() ? 'Projects' : ''">
        <span nz-icon nzType="partition"></span>
        <span>Projects</span>
      </li>-->
      <li nz-submenu>
        <div title class="w-100" [routerLink]="['/projects', activeTenant()?.id]" [queryParams]="{ folder: null }">
          <span nz-icon nzType="partition"></span>
          <span>Projects</span>
        </div>
        <div class="p-1" nzTheme="dark" data-testid="folders-tree">
          @if (folderTree().length) {
            <nz-tree #nzTreeComponent [nzData]="folderTree()" nzShowIcon (nzClick)="clickNode($event)"></nz-tree>
          } @else {
            <div class="text-center py-3">No folders found</div>
          }
        </div>
      </li>
      <li
        nz-menu-item
        [routerLink]="['/coming-soon']"
        [queryParams]="{ folder: null }"
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed() ? 'Favourite Projects' : ''">
        <span nz-icon nzType="star" nzTheme="outline"></span>
        <span>Favourite Projects</span>
      </li>
      <li
        nz-menu-item
        [routerLink]="['/coming-soon']"
        [queryParams]="{ folder: null }"
        nz-tooltip
        nzTooltipPlacement="right"
        [nzTooltipTitle]="isCollapsed() ? 'Last Updated DoEs' : ''">
        <span nz-icon nzType="clock-circle" nzTheme="outline"></span>
        <span>Last Updated DoEs</span>
      </li>
    </ul>
    <div class="fxFlex"></div>
    <!--<div class="separator"></div>
    <ul class="outer-bottom-micro" nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed()">
      <li
        nz-menu-item
        (click)="goToAdmin()"
        [nzTooltipTitle]="isCollapsed() ? 'Settings' : null"
        nzTooltipPlacement="right"
        nz-tooltip>
        <span nz-icon nzType="control"></span>
        <span>Settings</span>
      </li>
    </ul>-->
  </div>
</div>
