import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { catchError, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';

export const httpErrorInterceptor: HttpInterceptorFn = (req, next) => {
  const notificationService = inject(NzNotificationService);
  const router = inject(Router);
  const authService = inject(AuthService);

  return next(req).pipe(
    catchError((error) => {
      let errorMessage = '';
      if (error instanceof ErrorEvent) {
        errorMessage = `${error.error.message}`;
      } else {
        const serverError = error.error || error;
        errorMessage = `${serverError.status} - ${serverError.detail}`;
      }
      console.error(error.status, errorMessage);
      if ([401, 403].includes(error.status)) {
        if (authService.hasToken()) {
          authService.logout();
          router.navigate(['/error', error.status]);
        } else {
          authService.logout();
          router.navigate(['/login']);
        }
      } else {
        notificationService.create('error', 'Error', `${errorMessage}`, { nzDuration: 5000 });
      }
      return throwError(() => errorMessage);
    }),
  );
};
